<template>
    <div style="height:100%;">
        <a-layout style="height:100%;">
            <a-layout-content style="height:100%;">
                <div class="yj-conten" style="min-height: calc(100% - 48px);background-color:#fff;width:calc(100% - 32px);">
                    <a-card title="活动描述" style="width:100%;min-height:100%;" :bordered="false">
                        <a-form-model layout="horizontal" style="width:800px;"
                                      ref="ruleForm"
                                      :rules="rules"
                                      :model="FormModel"
                                      :label-col="formItemLayout.labelCol"
                                      :wrapper-col="formItemLayout.wrapperCol">
                            <a-form-model-item label="活动名称" prop="Name">
                                <a-input placeholder="请输名称" v-model="FormModel.Name" type="text" :disabled="disabled" />
                            </a-form-model-item>
                            <a-form-model-item label="开始时间"
                                               required
                                               prop="BeginTime">
                                <a-date-picker v-model="FormModel.BeginTime"
                                               show-time
                                               type="date"
                                               :disabled-date="disabledStartDate"
                                               format="YYYY-MM-DD HH:mm:ss"
                                               placeholder="选择开始时间"
                                               style="width:100%"
                                               @openChange="handleStartOpenChange" :disabled="disabled" />
                            </a-form-model-item>
                            <a-form-model-item label="结束时间"
                                               required
                                               prop="EndTime">
                                <a-date-picker show-time
                                               style="width:100%"
                                               type="date"
                                               :disabled-date="disabledEndDate"
                                               format="YYYY-MM-DD HH:mm:ss"
                                               placeholder="选择结束时间"
                                               :open="endOpen"
                                               @openChange="handleEndOpenChange"
                                               v-model="FormModel.EndTime" :disabled="disabled" />
                            </a-form-model-item>
                            <a-form-model-item label="选择商品" style="display:none">
                                <a-input placeholder="点击选择商品" disabled v-model="FormModel.ProductName" />
                                <a-button type="primary" value="点击选择" size="small" @click="showProduct" :disabled="Product.disabled">点击选择</a-button>
                            </a-form-model-item>
                            <a-form-model-item label="位置区域">
                                <a-switch v-model="FormModel.IsLoaction" :disabled="disabled">
                                    <a-icon slot="checkedChildren" type="check" />
                                    <a-icon slot="unCheckedChildren" type="close" />
                                </a-switch>
                                <a-button type="primary" value="点击选择" @click="map_display" v-show="FormModel.IsLoaction" size="small" style="margin-left:12px;" :disabled="disabled">点击选择</a-button>
                            </a-form-model-item>
                            <a-form-model-item label="地址" v-show="FormModel.IsLoaction">
                                <a-input placeholder="地址" type="textarea" disabled v-model="mapModel.tempaddress" />
                            </a-form-model-item>
                            <a-form-model-item label="浏览时长(秒)" prop="ReadSecond">
                                <a-input-number :min="1" :step="1" v-model="FormModel.ReadSecond" :disabled="disabled" />
                            </a-form-model-item>
                            <a-form-model-item has-feedback prop="ShareMoney" label="分享金额" style="display:none">
                                <a-input-number :min="0" :step="0" v-model="FormModel.AwardLuckMoney.ShareMoney" :disabled="disabled" :precision="2" />
                            </a-form-model-item>
                            <a-form-model-item has-feedback prop="ReadMoney" label="阅读金额">
                                <a-input-number :min="0" :step="0" v-model="FormModel.AwardLuckMoney.ReadMoney" :disabled="disabled" :precision="2" />
                            </a-form-model-item>
                            <a-form-model-item has-feedback prop="PersonMaxMoney" label="单人最高金额">
                                <a-input-number :min="0" :step="0" v-model="FormModel.AwardLuckMoney.PersonMaxMoney" :disabled="disabled" :precision="2" />
                            </a-form-model-item>
                            <a-form-model-item has-feedback prop="BudgetMoney" label="活动最高金额">
                                <a-input-number :min="0" :step="0" v-model="FormModel.AwardLuckMoney.BudgetMoney" :disabled="disabled" :precision="2" />
                            </a-form-model-item>
                            <a-form-model-item label="活动说明" style="margin-bottom:10px;"
                                               :label-col="formItemLayout.labelCol"
                                               :wrapper-col="formItemLayout.wrapperCol"
                                               prop="RuleSummary">
                                <Rich v-bind:value.sync="FormModel.RuleSummary" rows="800"></Rich>
                            </a-form-model-item>
                        </a-form-model>
                    </a-card>
                    <a-card>
                        <a-button type="primary" @click="submitForm('ruleForm')" :disabled="disabled">
                            保存
                        </a-button>
                    </a-card>
                </div>
            </a-layout-content>
        </a-layout>
        <a-modal v-model="mapModelDisplay"
                 title="选择活动区域"
                 centered
                 okText="确定"
                 cancelText="取消"
                 width="900px"
                 height="700px"
                 @ok="confirmAddress">
            <div style="width: 100%; height:auto;">
                <div id="map_title">
                    请以当前
                    <a href="javascript:void(0)" onclick="map_location();" style="color:red;font-weight:400;font-size:20px;">您的位置</a>为中心，选择本次活动范围。
                    <br>
                    <span>备注：可拖动红色中心标示，改变您当前位置</span>
                </div>
                <div id="map_sel">
                    <a-select :default-value="circlePath.radius" style="width: 120px" @change="radiusChange">
                        <a-select-option value="0">请选择活动区域</a-select-option>
                        <a-select-option value="5000">5公里</a-select-option>
                        <a-select-option value="10000">10公里</a-select-option>
                        <a-select-option value="15000">15公里</a-select-option>
                        <a-select-option value="20000">20公里</a-select-option>
                        <a-select-option value="30000">30公里</a-select-option>
                        <a-select-option value="50000">50公里</a-select-option>
                        <a-select-option value="100000">100公里</a-select-option>
                        <a-select-option value="-1">不限制</a-select-option>
                    </a-select>
                </div>
                <baidu-map class="bm-view" ak="E8f3c743837925ce120eb93417f478db" :center="mapModel.center" :zoom="mapModel.zoom" :scroll-wheel-zoom="true" @ready="mapReady">
                    <bm-marker :position="mapModel.center" :dragging="true" @dragend="dragend">
                    </bm-marker>
                    <bm-circle :center="circlePath.center" :radius="circlePath.radius" :editing="circlePath.editing" stroke-color="blue" :stroke-opacity="0.5" :stroke-weight="2"></bm-circle>
                </baidu-map>
            </div>
        </a-modal>
        <a-modal v-model="Product.IsDisplay"
                 title="文章选择"
                 centered
                 okText="确定"
                 cancelText="取消"
                 width="900px"
                 height="700px"
                 @ok="onProductOk">
            <div style="width: 100%; height:auto;">
                <ul class="form-search">
                    <li style="margin:0">
                        <label>名称</label>
                        <a-input placeholder="输入名称" v-model="Product.Name"></a-input>
                    </li>
                </ul>
                <a-button type="primary" value="查询" @click="getProductList" size="small" style="margin-left:12px;height:28px;padding-left:12px;padding-right:12px;">查询</a-button>
                <div id="boxdiv" style="padding-top:16px;">
                    <a-radio-group v-model="Product.ID" @change="onProductChange">
                        <table id="example2" class="table  table-hover">
                            <thead>
                                <tr>
                                    <th width="40"></th>
                                    <th width="100">商品图片</th>
                                    <th>名称</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in Product.tableData" :key="item.ID">
                                    <td>
                                        <a-radio :value="item.ID"></a-radio>
                                    </td>
                                    <td align="center">
                                        <img :src="Getsrc(item)" height="80" width="80" />
                                    </td>
                                    <td title="Name">{{item.Name}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </a-radio-group>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
    import Rich from "@/components/RichEditor.vue";
    import util from "@/Plugin/util.js";
    import http from "@/Plugin/Http.js";
    import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
    import { BmCircle } from 'vue-baidu-map'
    import { BmMarker } from 'vue-baidu-map'
    export default {
        name: "",
        data() {
            return {
                rules: {
                    Name: [{ message: "请输入活动名称", required: true, trigger: "blur" }],
                    BeginTime: [{ message: "请输入开始时间", required: true, trigger: "change" }],
                    EndTime: [{ message: "请输入结束时间", required: true, trigger: "change" }]
                },
                FormModel: {
                    Name: "",
                    BeginTime: "",
                    EndTime: "",
                    IsLoaction: false,
                    Lat: "36.06105",
                    Lng: "114.382974",
                    Radius: 10,
                    ProductName: "",
                    RuleSummary: "",
                    ReadSecond: 1,
                    AwardLuckMoney: {
                        ShareMoney: 0,
                        ReadMoney: 0,
                        PersonMaxMoney: 0,
                        BudgetMoney: 0
                    }
                },
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 5 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 19 },
                    },
                },
                disabled: false,
                ID: 0,
                endOpen: false,
                mapModelDisplay: false,
                circlePath: {
                    center: {
                        lng: 114.383176,
                        lat: 36.062233
                    },
                    radius: "10000",
                    editing: false
                },
                mapModel: {
                    center: { lng: 114.383176, lat: 36.062233 },
                    zoom: 13,
                    tempaddress: "以【 河南省安阳市文峰区弦歌大道 】为中心方圆【 10 】公里"
                },
                gec: null,
                Product: {
                    ID: 0,
                    IsDisplay: false,
                    Name: '',
                    tableData: [],
                    disabled: false,
                    ProductType: 0
                }
            };
        },
        props: {//组件属性
        },
        methods: {//方法
            submitForm(formName) {
                this.$refs[formName].validate(valid => {
                    if (valid) {
                        var self = this;
                        if (self.checkProduct()) {
                            var op = {
                                url: "/MarketingModule/M2Activity/AddM2ActivityInfo",
                                data: {
                                    ID: self.ID,
                                    Name: self.FormModel.Name,
                                    BeginTime: self.FormModel.BeginTime,
                                    EndTime: self.FormModel.EndTime,
                                    IsLoaction: self.FormModel.IsLoaction,
                                    Lat: self.FormModel.Lat,
                                    Lng: self.FormModel.Lng,
                                    Radius: self.FormModel.Radius,
                                    AwardLuckMoney: self.FormModel.AwardLuckMoney,
                                    RuleSummary: self.FormModel.RuleSummary,
                                    ReadSecond: self.FormModel.ReadSecond
                                },
                                OnSuccess: function (response) {
                                    console.log(formName + response);
                                    self.ID = response.data
                                    self.$message.success('保存成功', 1.5, function () { });
                                    self.$router.push({
                                        name: "M2Activity_List"
                                    });
                                }
                            };
                            http.Post(op);
                        }
                    } else {
                        return false;
                    }
                });
            },
            disabledStartDate(startValue) {
                const endValue = this.FormModel.EndTime;
                if (!startValue || !endValue) {
                    return false;
                }
                return startValue.valueOf() > endValue.valueOf();
            },
            disabledEndDate(endValue) {
                const startValue = this.FormModel.BeginTime;
                if (!endValue || !startValue) {
                    return false;
                }
                return startValue.valueOf() >= endValue.valueOf();
            },
            handleStartOpenChange(open) {
                if (!open) {
                    this.endOpen = true;
                }
            },
            handleEndOpenChange(open) {
                this.endOpen = open;
            },
            map_display: function () {
                var self = this;
                self.mapModelDisplay = true;
            },
            confirmAddress: function () {
                var self = this;
                self.mapModelDisplay = false;
                //确认地理位置
            },
            radiusChange: function (value) {
                var self = this;
                self.circlePath.radius = value;
                self.FormModel.Radius = value / 1000;
            },
            mapReady: function ({ BMap, map }) {
                console.log(map);
                var self = this;
                self.gec = new BMap.Geocoder();
                self.FormModel.Lng = self.mapModel.center.lng;
                self.FormModel.Lat = self.mapModel.center.lat;
            },
            dragend: function (type) {
                var self = this;
                self.circlePath.center = type.point;
                self.FormModel.Lng = type.point.lng;
                self.FormModel.Lat = type.point.lat;
                self.gec.getLocation(type.point, function (rs) {
                    var addComp = rs.addressComponents;
                    var trainPlace = addComp.province + addComp.city + addComp.district + addComp.street + addComp.streetNumber;
                    var content = trainPlace;
                    self.mapModel.tempaddress = content;
                });
            },
            getTableData: function (id) {
                var self = this;
                var op = {
                    url: "/MarketingModule/M2Activity/GetM2ActivityInfo",
                    data: {
                        id: id
                    },
                    OnSuccess: function (data) {
                        self.FormModel = data.data;
                        if (data.data.IsLoaction) {
                            self.mapModel.tempaddress = data.data.tempaddress;
                            self.circlePath.radius = (data.data.Radius * 1000).toString();
                        }
                        self.FormModel.BeginTime = util.TimeStamptoDateTime(data.data.BeginTime, 'yyyy-MM-dd hh:mm:ss');
                        self.FormModel.EndTime = util.TimeStamptoDateTime(data.data.EndTime, 'yyyy-MM-dd hh:mm:ss');
                    }
                };
                http.Post(op);
            },
            showProduct: function () {
                var self = this;
                self.Product.IsDisplay = true;
            },
            onProductOk: function () {
                var self = this;
                self.Product.IsDisplay = false;
                var result = self.Product.tableData.filter(function (item) {
                    return item.ID == self.Product.ID;
                });
                self.FormModel.ProductName = result[0].Name;
                self.Product.ProductType = result[0].ProductType;
            },
            getProductList: function () {
                var self = this
                var op = {
                    url: "/ShopModule/Product/ProductList",
                    data: {
                        Name: self.Product.Name,
                        M2ActivityID: 0
                    },
                    OnSuccess: function (data) {
                        if (data.data.Data.length > 0) {
                            self.Product.tableData = data.data.Data;
                            self.Product.ID = data.data.Data[0].ID;
                        } else {
                            self.Product.tableData = [];
                            self.Product.ID = 0;
                        }
                    }
                };
                http.Post(op);
            },
            onProductChange(e) {
                var self = this;
                self.Product.ID = e.target.value;
            },
            Getsrc(e) {
                var src = '#';
                if (e.ProductExImage.length > 0) {
                    src = e.ProductExImage[0].ImagePath;
                }
                return src;
            },
            checkProduct: function () {
                var rt = true;
                //var self = this;
                //if (self.FormModel.ProductName == '' && self.ID == 0) {
                //    rt = false;
                //    self.$message.error("请选择商品！");
                //}
                return rt;
            }
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
            BaiduMap,
            BmCircle,
            BmMarker,
            Rich
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
            this.ID = this.$route.query.ID;
            if (this.$route.query.disabled == 1) {
                this.disabled = true;
            }
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            if (this.ID != 0) {
                this.getTableData(this.ID);
                this.Product.disabled = true;
            }
            this.getProductList();
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>
<style type="text/css">
    .bm-view {
        width: 100%;
        height: 600px;
    }

    #map_title {
        position: absolute;
        z-index: 9999;
        left: 23px;
        right: 23px;
        height: 60px;
        background-color: #09f;
        padding-top: 10px;
        padding-left: 20px;
        color: white;
    }

    #map_sel {
        position: absolute;
        z-index: 9999;
        right: 106px;
        top: 92px;
    }

    #map_btns {
        position: absolute;
        z-index: 9999;
        bottom: 30px;
        width: 100%;
    }

        #map_btns input[type='button'] {
            width: 120px;
            height: 45px;
            margin-top: 20px;
            color: #fff;
            border: none;
            border-radius: 30px;
            opacity: 0.8;
        }

    .map_save {
        background-color: green;
        margin-right: 40px;
    }

    .map_cancel {
        background-color: #f15b5b;
    }

    .solid-btn {
        background: #32a9ff;
        color: #fff !important;
        padding: 4px 18px;
        border: none;
        border-radius: 2px;
        transition: all .15s ease-in-out;
        margin-top: -3px;
    }

    .yj-storecon-template > div {
        height: 100px;
        width: 100px;
        overflow: hidden;
        text-align: center;
        flex-shrink: 0;
    }
</style>
<style type="text/css">
    .content {
        min-height: 250px;
        padding: 15px;
        margin-right: auto;
        margin-left: auto;
        padding-left: 15px;
        padding-right: 15px;
    }

    .content {
        margin: 15px 20px 15px 15px;
        padding-top: 0;
    }

    .form-search {
        display: block;
        list-style-type: disc;
        padding-left: 0;
    }

    ul, ol {
        margin-top: 0;
        margin-bottom: 10px;
    }

    .box {
        border-top: none;
        box-shadow: none;
    }

    .right-btn {
        margin-top: 18px;
    }

    .line-btn {
        border: 1px solid #32a9ff;
        color: #32a9ff;
        padding: 7px 15px;
        background: #fff;
        transition: all .15s ease-in-out;
    }

    .gray-line-btn {
        border: 1px solid #e5e5e5;
        color: #666;
        padding: 7px 15px;
        background: #fff;
        transition: all .15s ease-in-out;
        margin-left: 20px;
    }

    .gray-line-btn {
        cursor: pointer
    }

    .solid-btn {
        background: #32a9ff;
        color: #fff !important;
        padding: 4px 18px;
        border: none;
        border-radius: 2px;
        transition: all .15s ease-in-out;
        margin-top: -3px;
    }

        .solid-btn:hover {
            background: #1b99f5;
            cursor: pointer;
            color: #fff
        }

    .num {
        font-size: 26px;
        color: #32a9ff;
        padding: 5px;
        font-weight: 500;
    }

    .inners {
        padding: 10px;
        border: 1px solid #f3f4f5
    }

    .search {
        overflow: hidden;
        background: #fbfbfb;
        margin: 10px auto;
        padding: 10px;
    }

    .line-btn:hover {
        background: #32a9ff;
        color: #fff;
        border-radius: 2px;
        transition: all .15s ease-in-out;
        cursor: pointer;
    }

    .form-search {
        display: block;
        list-style-type: disc;
        padding-left: 0;
    }

    .mtb5 {
        margin: 5px 0
    }

    .mtb10 {
        margin: 10px 0;
    }

    .mtb20 {
        margin: 20px 0;
    }

    .tr-middle td {
        vertical-align: middle;
    }

    body {
        background: #f3f5f5;
        font-family: Arial,Helvetica,sans-serif,"Microsoft YaHei","ËÎÌå";
        font-size: 15px;
    }

    .sys-name {
        color: #00a2e9;
        margin: 0;
        padding: 0;
        font-size: 25px;
        font-weight: 500;
        height: 68px;
        line-height: 68px;
    }

    .right-opt {
        height: 68px;
        line-height: 68px;
        cursor: pointer;
    }

    .top-top {
        margin-bottom: 10px;
        background: #fff;
        border-bottom: solid 1px #E0E5E5;
    }

    .top-center {
        max-width: 1160px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
    }

    .content-wrapper {
        background: #fff;
    }

    .content {
        margin: 15px 20px 15px 15px;
        padding-top: 0
    }

    .box-header {
        border-bottom: 1px solid #f3f5f5;
        padding: 0 10px;
    }

        .box-header > .box-title {
            height: 62px;
            line-height: 62px;
        }

    .top-center a[href] {
        color: #32a9ff;
    }

    a {
        color: #32a9ff;
    }

    .pager li > a {
        border: none;
    }

    .main-right-search {
        padding: 20px;
        border-radius: 4px;
        -moz-border-radius: 4px;
        background-color: #fbfbfb;
        padding-top: 0;
    }

    .form-search li {
        float: left;
        overflow: hidden;
        margin-right: 17px;
        width: 350px;
        height: 28px;
        line-height: 28px;
        font-size: 14px;
        border: solid 1px #E0E5E5;
        border-radius: 4px;
        -moz-border-radius: 4px;
        list-style: none;
        margin-top: 8px;
        margin-bottom: 0;
        padding: 0;
    }

        .form-search li label {
            float: left;
            width: 110px;
            color: #666;
            text-align: center;
            border-right: solid 1px #E0E5E5;
        }

        .form-search li input {
            float: left;
            border: none;
            background: none;
            outline: none;
            text-indent: 6px;
            -webkit-appearance: none;
            background-color: #ffffff;
            width: 235px;
            height: 26px;
            color: #333333;
            line-height: 28px;
        }

    .form-search-margin {
        margin-top: 12px;
    }

    .form-search li .inline-text {
        padding-left: 5px;
    }

    .pager {
        padding-left: 0;
        margin: 20px 0;
        text-align: center;
        list-style: none;
    }

        .pager li {
            display: inline;
        }

        .pager ul li {
            list-style: none outside none;
        }

        .pager li > a {
            border: none;
        }

        .pager li > a, .pager li > span {
            display: inline-block;
            padding: 5px 14px;
            background-color: #fff;
            border-radius: 15px;
        }

    .table {
        width: 100%;
        margin-bottom: 20px;
    }
</style>